import React, { useState } from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'

import token from 'src/static/images/banner-ido/token-ido.png'
import chain from 'src/static/logos/ido/chain.svg'
import magic from 'src/static/logos/ido/magic.svg'
import poolz from 'src/static/logos/ido/poolz.svg'
import dexcheckpad from 'src/static/logos/ido/dexcheck.svg'
import gainspad from 'src/static/logos/ido/gainspad.svg'
import seedlogo from 'src/static/logos/ido/seedlogo.svg'

import './IdoBanner.sass'

export default function IdoBanner() {
  const [open, setOpen] = useState(true)
  return (
    <div id="ido-banner" className={open ? '' : 'mini'}>
      <img src={token} alt="lay3r token" />
      <h1 onClick={() => setOpen(true)}>
        <span>
          <MarkdownMessage id="ido-banner.text" />
        </span>
      </h1>
      <div className="icon icon-close" onClick={() => setOpen(false)} />
      <div className="logo-grid">
        <a
          href="https://pad.chaingpt.org/buy-token/151?utm_source=twitter&utm_medium=social&utm_campaign=autolayer_ido"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={chain} alt="ChainGPT" />
          <span>ChainGPT</span>
          <strong>SOLD OUT</strong>
        </a>
        <a
          href="https://launchpad.dexcheck.ai/buy-token/26"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={dexcheckpad} alt="DexCheck Pad" />
          <span>DexCheck Pad</span>
          <strong>SOLD OUT</strong>
        </a>
        <a
          href="https://pad.gains-associates.com/project/d27009db-57d7-45ab-aa11-e9955e851776"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={gainspad} alt="GainsPad" />
          <span>GainsPad</span>
          <strong>SOLD OUT</strong>
        </a>
        <a
          href="https://seedify.fund/igo/66f6db13f9c7df513f7f56d9"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={seedlogo} alt="Seedify" />
          <span>Seedify</span>
          <strong>SOLD OUT</strong>
        </a>
        <a
          href="https://launchpad.magicsquare.io/projects/autolayer"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={magic} alt="Magic Launchpad" />
          <span>Magic Launchpad</span>
          <strong>SOLD OUT</strong>
        </a>
        <a
          href="https://www.poolz.finance/project-details/about/219"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={poolz} alt="Poolz Finance" />
          <span>Poolz Finance</span>
          <strong>SOLD OUT</strong>
        </a>
      </div>
    </div>
  )
}
