import React, { RefObject, useRef } from 'react'
import { Link } from 'react-router-dom'
import useIsResponsive from 'src/hooks/useIsResponsive'
import LanguageSelector from 'src/components/LanguageSelectorHeader/LanguageSelectorHeader'
import LaunchFast from 'src/components/LaunchFast/LaunchFast'
import Navigation from './Navigation'
import Menu from './Menu/Menu'
import { useHeaderScroll } from 'src/hooks/useHeaderScroll'
import GoToAirdropChecker from '../GoToAirdropChecker/GoToAirdropChecker'

import logo from 'src/static/images/logos/autolayer-logo-dark.svg'
import logo2 from 'src/static/images/logos/autolayer-logo-white.svg'

import './Header.sass'

interface HeaderProps {
  scrollable: RefObject<HTMLDivElement>
}

const Header = ({ scrollable }: HeaderProps) => {
  const isResponsive = useIsResponsive()
  const headerRef = useRef<HTMLElement>(null)
  const { class: headerClass } = useHeaderScroll(scrollable, headerRef)

  return (
    <header
      id="landing-masthead"
      // className={`${headerClass} ${show ? '' : 'hide'}`}
      className={`${headerClass}`}
      ref={headerRef}
    >
      <GoToAirdropChecker />
      <div className="container">
        <section className="left-side">
          <Link className="link header-logo" to="/">
            <img
              loading="eager"
              className="logo-image"
              src={
                headerClass === 'white' ||
                headerClass === 'blue' ||
                headerClass === 'default'
                  ? logo2
                  : logo
              }
              alt="autolayer logo"
            />
          </Link>
        </section>
        <section className="center">
          <Navigation />
        </section>
        <section className="right-side">
          <LanguageSelector />
          {isResponsive ? <Menu /> : <LaunchFast text="landing-hero-banner" />}
        </section>
      </div>
    </header>
  )
}

export default Header
